import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { useQuery } from '@tanstack/react-query';
import { getReports, openPDFByName, downloadDocumentByName } from '../../api/requests';
import { Table } from '../../components/Table';
import { useCompanyData } from '../../hooks/useCompanyData';
import { Loader } from '../../components/Loader/Loader';
import { Button } from '../../components/Button';

export const Reports = () => {
  const { companyId } = useParams();
  const { companyData, industryData } = useCompanyData(companyId);

  const { data: reportsData, isLoading } = useQuery({
    queryKey: ['reports', companyData?.inn],
    queryFn: async () => {
      const data = await getReports();
      return data?.filter((_) => _.company_inn === companyData?.inn);
    },
    enabled: !!companyData?.inn,
  });

  const [pdfLoading, setPdfLoading] = useState<string>();

  const handleOpenPdf = async (name?: string) => {
    setPdfLoading(name);
    await openPDFByName(name);
    setPdfLoading('');
  };

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name} - Отчёты</Heading>
      <Heading size="S" className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </Heading>
      <Heading size="S">Годовые отчёты компании</Heading>
      <Table
        cols={['Год', 'Скачать файл', 'Открыть файл']}
        rows={reportsData?.map((_) => [
          { cell: _.year, sortValue: _.year },
          {
            cell: (
              <Button
                key={_.id}
                onClick={() => downloadDocumentByName(_.report_txt_name)}
                className="!text-link hover:underline"
                variant="no-bg"
              >
                .txt
              </Button>
            ),
            className: '!py-0 w-[125px]',
          },
          {
            cell:
              pdfLoading === _.report_PDF_name ? (
                <Loader size="S" />
              ) : (
                <Button
                  key={_.id}
                  onClick={() => handleOpenPdf(_.report_PDF_name)}
                  className="!text-link hover:underline"
                  variant="no-bg"
                >
                  .pdf
                </Button>
              ),
            className: '!py-0 w-[125px]',
          },
        ])}
      />
      {isLoading ? <Loader /> : null}
      {!reportsData?.length && !isLoading ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные об отчётах о компании отсутствуют
        </Heading>
      ) : null}
    </div>
  );
};
