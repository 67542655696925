import { API_BASE_URL, getRequest, LOGIN_API_BASE_URL } from './methods';
import {
  type NewsItem,
  type Company,
  type CompanyResponse,
  type GeneralRating,
  type Industry,
  type RatingESG,
  type RatingEthics,
  type ReportData,
  type WebSiteData,
  type WebSiteParsingData,
  type Reuter,
  type LetterData,
  type AuthData,
  type AccountData,
} from './types';

export const getGeneralRatings = () => getRequest<GeneralRating[]>('/general-ratings');

export const getRatingById = (id: number) => getRequest<GeneralRating>(`/general-ratings/${id}`);

export const getESGRatings = () => getRequest<RatingESG[]>(`/ratings-esg`);

export const getESGRatingById = (id: number) => getRequest<RatingESG>(`/ratings-esg/${id}`);

export const getEthicsRatings = () => getRequest<RatingEthics[]>(`/ratings-ethics`);

export const getEthicsRatingById = (id: number) =>
  getRequest<RatingEthics>(`/ratings-ethics/${id}`);

export const getCompanies = () => getRequest<CompanyResponse[]>('/companies');

export const getCompanyById = (id: number) => getRequest<Company>(`/companies/${id}`);

export const getIndustryById = (id: number) => getRequest<Industry>(`/industries/${id}`);

export const getReports = () => getRequest<ReportData[]>(`/reports/all`);

export const getWebSites = () => getRequest<WebSiteData[]>(`/websites/all`);

export const getWebSitesParsings = () => getRequest<WebSiteParsingData[]>(`/websites-parsing`);

export const getNews = () => getRequest<NewsItem[]>(`/news`);

export const getReuters = () => getRequest<Reuter[]>('/reuter');

export const getLettersData = () => getRequest<LetterData[]>('/letter_color');

export const downloadDocumentByName = async (name?: string) =>
  fetch(`${API_BASE_URL}/reports/download-txt/${name}/`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name || '';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error('Ошибка при скачивании файла:', error));

export const openPDFByName = async (name?: string) =>
  fetch(`${API_BASE_URL}/reports/download-pdf/${name}/`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error('Ошибка при скачивании файла:', error));

export const logout = () => window.localStorage.removeItem('token');

export const login = (data: AuthData) =>
  fetch(`${LOGIN_API_BASE_URL}/accounts/authenticate`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async (res) => {
    const body = await res.json();
    localStorage.setItem('token', body.token);
    localStorage.setItem('userId', body.userId);
  });

export const getUserData = async (userId: string) => {
  const data = await fetch(`${LOGIN_API_BASE_URL}/accounts/get-account/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') || '',
    },
  });

  if (data.status === 401) {
    logout();
  }

  return await data.json();
};

export const setUserData = async (userId: string, userData: AccountData) => {
  const data = await fetch(`${LOGIN_API_BASE_URL}/accounts/update-account/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') || '',
    },
  });

  if (data.status === 401) {
    logout();
  }

  return await data.json();
};
